import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCurrentUser, userLogout, userLogin } from "../api";
import { captureException, setUser as setSentryUser } from "@sentry/react";
import { useConfirm } from "../hooks";

const AuthContext = createContext({});

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [adminUser, setAdminUser] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const confirm = useConfirm();

  const reloadUser = useCallback(
    () =>
      getCurrentUser().then((resp) => {
        if (resp) {
          const { user, adminUser } = resp;
          setUser(user);
          setAdminUser(adminUser);
          setSentryUser(user);
        } else {
          setUser(undefined);
          setAdminUser(undefined);
          setSentryUser(undefined);
        }
      }),
    []
  );

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    reloadUser().finally(() => setLoadingInitial(false));
  }, [reloadUser]);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = useCallback(
    (email, password) => {
      setLoading(true);

      return userLogin({ email, password })
        .then((response) => {
          const user = response.data.user;

          setUser(user);

          setSentryUser(user);

          // used in profitwell
          localStorage.setItem("email", user.email);

          return user;
        })
        .catch((error) => {
          captureException(error);

          console.error(error);

          const response = error.response;

          if (!response?.data || !response.data.msg) {
            confirm("Something went wrong");
            return;
          }

          confirm(response.data.msg);
        })
        .finally(() => setLoading(false));
    },
    [confirm]
  );

  // Call the logout endpoint and then remove the user
  // from the state.
  const logout = useCallback(() => {
    return userLogout()
      .catch((err) => {
        captureException(err);
        console.error(err);
        confirm("Please check internet connection or try again");
      })
      .then(reloadUser);
  }, [confirm, reloadUser]);

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      adminUser,
      loading,
      login,
      logout,
      reloadUser,
    }),
    [user, adminUser, loading, login, logout, reloadUser]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
