import { useCallback, useRef, useEffect } from "react";
import { useConfirm as $useConfirm } from "material-ui-confirm";
import { ErrorOutline, InfoOutlined } from "@mui/icons-material";

const CONFIRM_CONFIG = {
  error: {
    title: "Error",
    color: "error",
    icon: ErrorOutline,
  },
  success: {
    title: "Success",
    color: "success",
    icon: InfoOutlined,
  },
};

export function useConfirm() {
  const confirm = $useConfirm();

  return useCallback(
    (props) => {
      let level = "error";
      let text = "";

      if (typeof props === "string") {
        text = props;
      } else {
        text = props.text;
        level = props.level || level;
      }

      const config = CONFIRM_CONFIG[level];

      return confirm({
        title: (
          <>
            {config.icon && (
              <config.icon fontSize="2rem" sx={{ marginRight: 1 }} />
            )}
            {config.title}
          </>
        ),
        titleProps: {
          variant: "h5",
          component: "h5",
          color: config.color,
          sx: { display: "inline-flex", alignItems: "center" },
        },
        description: text,
        confirmationText: "Ok",
        hideCancelButton: true,
        dialogProps: {
          maxWidth: "xs",
        },
      });
    },
    [confirm]
  );
}

export function useInterval(fn, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = fn;
  }, [fn]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [savedCallback, delay]);
}
