import { Box, Container, Grid, styled } from "@mui/material";

const StyledFooter = styled("footer")(({ theme }) => ({
  background: "#fff",
  color: "#23282c",
  borderTop: "1px solid #c8ced3",
  padding: 50,
  [theme.breakpoints.down("sm")]: {
    padding: 20,
  },
}));

export default function DefaultFooter() {
  return (
    <StyledFooter>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            Client Connect aggregates publicly available information from
            government, social and other sources. Because Client Connect only
            collects this data and does not create it, we cannot fully guarantee
            its accuracy.
          </Grid>

          <Grid item xs={12} sm={7} component={Box} textAlign="center">
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/mission`}
              >
                Mission
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/pricing`}
              >
                Pricing
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/blog`}
              >
                Blog
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/terms-of-use`}
              >
                Terms of Use
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/privacy-policy`}
              >
                Privacy Policy
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                component="a"
                href={`${process.env.REACT_APP_LANDING_URL}/notice-to-california-residents`}
              >
                Do Not Sell My Information
              </Grid>
            </Grid>

            <Box mt={4}>
              1700 Van Ness Ave San Francisco, CA 94109, USA <br />©{" "}
              {new Date().getFullYear()} Client Connect LLC, All rights reserved
            </Box>
          </Grid>
        </Grid>

        <Box mt={5} textAlign="center">
          Client Connect does not provide legal advice. If you need legal
          advice, please contact an attorney directly
        </Box>
      </Container>
    </StyledFooter>
  );
}
