import { useEffect, useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import {
  ExitToApp,
  GetApp,
  Menu as MenuIcon,
  Add as AddIcon,
  AdminPanelSettings,
} from "@mui/icons-material";
import { useCounties } from "../../api";
import useAuth from "../../contexts/auth";
import Logo from "../../assets/img/logo.svg";
import { US_STATES } from "../../constants";

function DefaultHeader({ toggleDrawer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState({
    all: false,
    withEmail: false,
  });
  const { user, loading, logout } = useAuth();
  const { counties } = useCounties();

  const navigate = useNavigate();

  const match = useMatch("/home/user/leads/:location");

  useEffect(() => {
    navigate(null, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return null;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const leadLocationName = match?.params?.location;

  const state = US_STATES[leadLocationName];

  let downloadParams = "";

  if (state) {
    downloadParams = `state=${leadLocationName}`;
  } else {
    downloadParams = `county=${leadLocationName}`;
  }

  const leadLocation =
    state || counties.find((county) => county.name === leadLocationName)?.name;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    logout().then(() => {
      navigate("/");
    });
  };

  return (
    <AppBar position="fixed" elevation={1}>
      <Toolbar variant="dense" style={{ justifyContent: "space-between" }}>
        {toggleDrawer && (
          <IconButton
            size="medium"
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <a href="/" style={{ flexGrow: 1 }}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Typography
              variant="h6"
              style={{
                color: "#0d3559",
                fontWeight: "bold",
                fontSize: 20,
                textDecoration: "none",
              }}
            >
              Client Connect
            </Typography>
          </Box>

          <Box
            component="img"
            src={Logo}
            alt="logo"
            style={{ width: 40 }}
            sx={{ display: { xs: "block", sm: "none" } }}
          />
        </a>

        <div>
          <Button
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            sx={{ margin: 1, display: { xs: "none", sm: "inline-flex" } }}
            href="/subscriptions/new"
          >
            Add Subscription
          </Button>

          {leadLocation && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                color="default"
                startIcon={<GetApp />}
                sx={{ margin: 1 }}
                onClick={handleMenu}
              >
                Download
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() =>
                    setSubMenuOpen({
                      withEmail: false,
                      all: !subMenuOpen.all,
                    })
                  }
                >
                  Enriched Arrest Data
                  <Menu
                    id="simple-sub-menu_2"
                    keepMounted
                    open={subMenuOpen.all}
                    anchorEl={anchorEl}
                  >
                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/download-csv?day=1&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        24 hours
                      </a>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/download-csv?day=7&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        7 days
                      </a>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/download-csv?day=30&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        30 days
                      </a>
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setSubMenuOpen({
                      all: false,
                      withEmail: !subMenuOpen.withEmail,
                    })
                  }
                >
                  Raw Arrest Data
                  <Menu
                    id="simple-sub-menu_1"
                    keepMounted
                    open={subMenuOpen.withEmail}
                    anchorEl={anchorEl}
                  >
                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/raw-download-csv?day=1&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        24 hours
                      </a>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/raw-download-csv?day=7&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        7 days
                      </a>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/leads/raw-download-csv?day=30&${downloadParams}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }}
                      >
                        30 days
                      </a>
                    </MenuItem>
                  </Menu>
                </MenuItem>
              </Menu>
            </>
          )}

          {user?.admin && (
            <Button
              variant="contained"
              color="default"
              startIcon={<AdminPanelSettings />}
              sx={{ margin: 1, display: { xs: "none", sm: "inline-flex" } }}
              href={`${process.env.REACT_APP_API_URL}/admin`}
              aria-label={""}
            >
              Admin panel
            </Button>
          )}

          <Button
            variant="contained"
            color="default"
            startIcon={<ExitToApp />}
            onClick={onLogout}
            sx={{ marginLeft: 1, marginRight: 0 }}
          >
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default DefaultHeader;
