import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://fb688f174ee44217ba145e7339611249@o4503965553983488.ingest.sentry.io/4504293930237952",
    environment: process.env.NODE_ENV,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
